@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@600&family=Ranchers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
.Homepage{
  height: 100%;
}

.heading{
  color: #333;
  font-weight: bold;
}


.sliderPage{
  height: 90vh;
}

.cap{
  font-family: Georgia, Times, 'Times New Roman', sans-serif;
}

.slider {
  position: relative;
  overflow: hidden;
  margin-top: 4%;
  height: 90vh;
  width: 100vw;;
}

.slide{
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}


.slide.current {
  opacity: 1;
}

.slide .content {
  position: absolute;
  bottom: 0px;
  left: -600px;
  opacity: 0;
  width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  padding: 20px;
}

.slide .content h1{
  margin-bottom: 10px;
}

.slide.current .content{
  opacity: 1;
  transform: translateX(600px);
  transition: all 0.7s ease-in-out 0.4s;
}


.buttons button#next {
  position: absolute;
  top: 80%;
  right: 2px;
}

.buttons button#prev {
  position: absolute;
  top: 80%;
  left: 15px;
}


.icons{
  position: absolute;
  margin-left: 84%;
  margin-top: 1.5%;
  width: 10%;
}


.icons svg{
  margin-bottom: 20%;
}


.notice{
  position: absolute;
  top: 128%;
  color: white;
  background-color: rgb(110, 1, 1);
  border: solid 2px black;
  margin-left: 10%;
  width: 40%;
  height: 22%;
  margin-bottom: 2%;
}

.notice p{
  color: white;
  font-family: 'Merriweather', Arial, Helvetica, sans-serif;
}



.buttons button {
  border: 2px solid #fff;
  background-color: grey;
  color: black;
  cursor: pointer;
  padding: 13px 15px;
  border-radius: 50%;
  outline: none;
}

.buttons button:hover{
  background-color: black;
  color: white;
}



.fc .fc-toolbar-title{
  color: darkorange;
}

.fc-icon-chevron-left:before{
  color: darkorange;
}

.fc-icon-chevron-right:before{
  color: darkorange;
}


@media only screen and (max-width: 1199px) and (min-width: 1000px) {
  .icons{
    position: absolute;
    margin-left: 80%;
    margin-top: 2%;
    width: 10%;
  }
  

  .notice{
    position: absolute;
    top: 121.8%;
    color: white;
    background-color: rgb(110, 1, 1);
    border: solid 2px black;
    margin-left: 10%;
    width: 50%;
    height: 25%;
    margin-top: 1%;
  }
}

@media only screen and (max-width: 999px) and (min-width: 601px) {
  .icons{
    position: absolute;
    margin-left: 75%;
    margin-top: 2%;
    width: 10%;
  }

  .notice{
    position: absolute;
    top: 121.8%;
    color: white;
    background-color: rgb(110, 1, 1);
    border: solid 2px black;
    margin-left: 10%;
    width: 50%;
    height: 35%;
    margin-top: 1%;
  }
}


@media only screen and (max-width: 600px) and (min-width: 300px) {
  .icons{
    position: relative;
    margin: 0 auto;
    margin-top: 10%;
    padding-left: 10%;
    margin-right: 10%;
    width: 90%;
  }

  .icons svg{
    margin-left: 10%;
    margin-bottom: 0;
  }

  
  .notice{
    position: relative;
    color: white;
    background-color: rgb(110, 1, 1);
    border: solid 2px black;
    margin: 0 auto;
    margin-top: -20%;
    margin-right: 9%;
    width: 80%;
    height: 60%;
  }

  
.slide{
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 80%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.buttons button#next {
  position: absolute;
  top: 65%;
  right: 2px;
}

.buttons button#prev {
  position: absolute;
  top: 65%;
  left: 15px;
}

}

.content p{
  font-family: 'Merriweather', Arial, Helvetica, sans-serif;
}



@media(max-width: 599px){
  .slide .content {
    bottom: -300px;
    left: 0px;
    width: 100%;
  }

  .slide.current .content {
    transform: translateY(-300px);
  }
}



.slide:first-child{
  background: url('../images/Slide_1.jpg') no-repeat center center/cover;
}

.slide:nth-child(2){
  background: url('../images/Slide_2.jpg') no-repeat center top/cover;
}

.slide:nth-child(3) {
  background: url('../images/Slide_3.jpg') no-repeat center top/cover;
}


.slide:nth-child(4) {
  background: url('../images/Slide_4.jpg') no-repeat center top/cover;
}