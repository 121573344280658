.fc{
  background-color: #580C1F;
}

.calendarr{
  position: absolute;
  width: 50%;
  margin-top: 1%;
  margin-left: 45%;
  margin-right: 20%;
}

a:not([href]):not([tabindex]){
  color: white;
}

.album{
  margin-top: 4%;
  border: solid 4px black;
}

.fantitle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: black;
  font-size: 5rem;
}



.iconsss{
  position: relative;
  margin-top: -15%;
  width: 20%;
}


.iconsss svg{
  margin-left: 15%;
}


.eventList{
  margin-top: 5%;
}


.column {
  float: right;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid{
  position: relative;
  text-align: right;
  width: 50%;
  margin-top: 32%;
  margin-left: 45%;
  padding-bottom: 5%;
}


.events{
  position: absolute;
  border: solid 2px black;
  margin-left: 5%;
  width: 35%;
  height: 59%;
  margin-top: 1%;
}





@media only screen and (max-width: 1299px) and (min-width: 1000px) {
 
  .fantitle{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-top: 2%;
    width: 300px;
    text-align: center;
    color: black;
    font-size: 4rem;
  }

  
.calendarr{
  position: absolute;
  width: 51%;
  margin-top: -10%;
  margin-left: 45%;
  margin-right: 20%;
}

.grid{
  position: relative;
  text-align: right;
  width: 50%;
  margin-top: 25%;
  margin-left: 45%;
}

.iconsss{
  position: relative;
  margin-top: 1%;
  width: 40%;
}

.iconsss svg{
  margin-left: 15%;
}

.events{
  position: absolute;
  border: solid 2px black;
  margin-left: 5%;
  width: 35%;
  height: 60%;
  margin-top: 1%;
}

}

@media only screen and (max-width: 999px) and (min-width: 700px) {
 
 
.fantitle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: black;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 5rem;
}
  .calendarr{
    position: relative;
    width: 90%;
    margin: 0 auto;
  }
  .grid{
    position: relative;
    width: 90%;
    margin: 0 auto;
  }

  .iconsss{
    position: relative;
    margin-top: 5%;
    width: 40%;
    margin: 0 auto;
  }
  
  .iconsss svg{
    margin-left: 15%;
  }

  .events{
    position: relative;
    border: solid 2px black;
    width: 90%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
}



@media only screen and (max-width: 699px) and (min-width: 300px) {
 
  .fantitle{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-top: 2%;
    width: 300px;
    text-align: center;
    color: black;
    font-size: 3rem;
  }

  .calendarr{
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .grid{
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  .iconsss{
    position: relative;
    margin-top: 5%;
    width: 80%;
    margin: 0 auto;
  }
  
  .iconsss svg{
    margin-left: 15%;
  }

  .events{
    position: relative;
    border: solid 2px black;
    margin-left: 0px;
    width: 100%;
    height: 100%;
    margin-top: 1%;
  }
}


@media only screen and (max-width: 1800px) and (min-width: 1300px) {
  .iconsss{
    position: relative;
    margin-top: -10%;
    width: 20%;
  }
  
  
  .iconsss svg{
    margin-left: 15%;
  }  

}