
  .button2{
    width: 5%;
    display: flex;
    position: absolute;
    left: 92%;
    top: 5%;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #FF9900;
    border-radius: 20px;
  }
  
  .Hidemessage{
    display: none;
  }
  .wrapper0 {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    justify-content: center;
    align-items: center;
    background-color: #13241F;
  }
  
  .form-wrapper {
    margin-bottom: 10%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }

  .hide{
      display: none;
  }


  .form-wrapper2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }

  .fancytitle{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: 2%;
    text-align: center;
    color: black;
    font-size: 5rem;
  }

  .caption{
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-weight: bold;
      margin-bottom: 2%;
      text-align: center;
      color: black;
  }

  .success{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: darkorange;
    margin-bottom: 10%;
    margin-top: 10%;
    text-align: center;
}


  .caption2{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 2%;
    text-align: center;
    color: black;
}
  

  
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  
  .label {
    font-size: 0.8em;
    margin-bottom: 0.25em;
    color: #222;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    margin-left: auto;
    width: 80%;
    margin-right: auto;
    border-radius: 20px;
    color: black;
    outline: none;
    border: 2px solid #C35111;
    background-color: transparent;
  }
  

  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }



  @media only screen and (max-width: 699px) and (min-width: 400px) {
  input {
    padding: 10px 10px;
    margin-left: auto;
    width: 100%;
    margin-right: auto;
    border-radius: 20px;
    color: black;
    outline: none;
    border: 2px solid #C35111;
    background-color: transparent;
  }
  .form-wrapper2 {
    width: 50%;
    display: flex;
    padding: 5px 5px;
    flex-direction: column;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }
  .form-wrapper {
    width: 50%;
    display: flex;
    padding: 5px 5px;
    flex-direction: column;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }
  }

  @media only screen and (max-width: 399px) and (min-width: 300px) {

    .form-wrapper2 {
      width: 100%;
      display: flex;
      padding: 5px 5px;
      flex-direction: column;
      box-shadow: 0px 10px 50px black;
      margin-left: auto;
      margin-right: auto;
    }
    .form-wrapper {
      width: 100%;
      display: flex;
      padding: 5px 5px;
      flex-direction: column;
      box-shadow: 0px 10px 50px black;
      margin-left: auto;
      margin-right: auto;
    }

  }

  

  input.error {
    border: 1px solid red;
    box-shadow: 0px 5px 10px red;
  }
  
  textarea.error {
    border: 1px solid red;
    box-shadow: 0px 5px 10px red;
  }

  .errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
    margin-top: 1%;
    margin-left: 12%;
  }
  
  
  
  .email,
  .name,
  .subject,
  .message,
  .name2,
  .email2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }


  textarea::placeholder{
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }

  .message textarea{
    padding: 10px 10px;
    margin-left: auto;
    width: 80%;
    margin-right: auto;
    border-radius: 20px;
    color: black;
    outline: none;
    border: 2px solid #C35111;
    background-color: transparent;
    height: 200px;
  }
  

  
  .createAccount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .button {
    background-color: grey;
    color: black;
    border: 2px solid #C35111;
    width: 40%;
    margin-top: 1em;
    border-radius: 10px;
    padding: 8px 0px;
    font-size: 1em;
    font-weight: lighter;
    letter-spacing: 1px;
    margin-bottom: 0.25em;
    outline: none;
  }
  
  
  .button:hover {
    color: darkorange;
    background-color: #13241F;
    border: 2px solid #C35111;
    box-shadow: 0px 5px 10px #C35111;
    outline: none;
  }

