@import url('https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: 'Crimson', Arial, Helvetica, sans-serif;
  background-image: url("../images/background.png");
  background-size: cover;
  background-repeat: no-repeat;
}


h2, h3, h5, h4, p, span, label, img {
  color: black;
}


.card {
  background-color: #13241F;
  margin-top: 20px
}