@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@600&family=Ranchers&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Crimson', Arial, Helvetica, sans-serif;
  background-image: url(/static/media/background.83323c19.png);
  background-size: cover;
  background-repeat: no-repeat;
}


h2, h3, h5, h4, p, span, label, img {
  color: black;
}


.card {
  background-color: #13241F;
  margin-top: 20px
}

.Homepage{
  height: 100%;
}

.heading{
  color: #333;
  font-weight: bold;
}


.sliderPage{
  height: 90vh;
}

.cap{
  font-family: Georgia, Times, 'Times New Roman', sans-serif;
}

.slider {
  position: relative;
  overflow: hidden;
  margin-top: 4%;
  height: 90vh;
  width: 100vw;;
}

.slide{
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}


.slide.current {
  opacity: 1;
}

.slide .content {
  position: absolute;
  bottom: 0px;
  left: -600px;
  opacity: 0;
  width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #333;
  padding: 20px;
}

.slide .content h1{
  margin-bottom: 10px;
}

.slide.current .content{
  opacity: 1;
  transform: translateX(600px);
  transition: all 0.7s ease-in-out 0.4s;
}


.buttons button#next {
  position: absolute;
  top: 80%;
  right: 2px;
}

.buttons button#prev {
  position: absolute;
  top: 80%;
  left: 15px;
}


.icons{
  position: absolute;
  margin-left: 84%;
  margin-top: 1.5%;
  width: 10%;
}


.icons svg{
  margin-bottom: 20%;
}


.notice{
  position: absolute;
  top: 128%;
  color: white;
  background-color: rgb(110, 1, 1);
  border: solid 2px black;
  margin-left: 10%;
  width: 40%;
  height: 22%;
  margin-bottom: 2%;
}

.notice p{
  color: white;
  font-family: 'Merriweather', Arial, Helvetica, sans-serif;
}



.buttons button {
  border: 2px solid #fff;
  background-color: grey;
  color: black;
  cursor: pointer;
  padding: 13px 15px;
  border-radius: 50%;
  outline: none;
}

.buttons button:hover{
  background-color: black;
  color: white;
}



.fc .fc-toolbar-title{
  color: darkorange;
}

.fc-icon-chevron-left:before{
  color: darkorange;
}

.fc-icon-chevron-right:before{
  color: darkorange;
}


@media only screen and (max-width: 1199px) and (min-width: 1000px) {
  .icons{
    position: absolute;
    margin-left: 80%;
    margin-top: 2%;
    width: 10%;
  }
  

  .notice{
    position: absolute;
    top: 121.8%;
    color: white;
    background-color: rgb(110, 1, 1);
    border: solid 2px black;
    margin-left: 10%;
    width: 50%;
    height: 25%;
    margin-top: 1%;
  }
}

@media only screen and (max-width: 999px) and (min-width: 601px) {
  .icons{
    position: absolute;
    margin-left: 75%;
    margin-top: 2%;
    width: 10%;
  }

  .notice{
    position: absolute;
    top: 121.8%;
    color: white;
    background-color: rgb(110, 1, 1);
    border: solid 2px black;
    margin-left: 10%;
    width: 50%;
    height: 35%;
    margin-top: 1%;
  }
}


@media only screen and (max-width: 600px) and (min-width: 300px) {
  .icons{
    position: relative;
    margin: 0 auto;
    margin-top: 10%;
    padding-left: 10%;
    margin-right: 10%;
    width: 90%;
  }

  .icons svg{
    margin-left: 10%;
    margin-bottom: 0;
  }

  
  .notice{
    position: relative;
    color: white;
    background-color: rgb(110, 1, 1);
    border: solid 2px black;
    margin: 0 auto;
    margin-top: -20%;
    margin-right: 9%;
    width: 80%;
    height: 60%;
  }

  
.slide{
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 80%;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.buttons button#next {
  position: absolute;
  top: 65%;
  right: 2px;
}

.buttons button#prev {
  position: absolute;
  top: 65%;
  left: 15px;
}

}

.content p{
  font-family: 'Merriweather', Arial, Helvetica, sans-serif;
}



@media(max-width: 599px){
  .slide .content {
    bottom: -300px;
    left: 0px;
    width: 100%;
  }

  .slide.current .content {
    transform: translateY(-300px);
  }
}



.slide:first-child{
  background: url(/static/media/Slide_1.e689d8d1.jpg) no-repeat center center/cover;
}

.slide:nth-child(2){
  background: url(/static/media/Slide_2.e0092597.jpg) no-repeat center top/cover;
}

.slide:nth-child(3) {
  background: url(/static/media/Slide_3.9543b34b.jpg) no-repeat center top/cover;
}


.slide:nth-child(4) {
  background: url(/static/media/Slide_4.32c22125.jpg) no-repeat center top/cover;
}
.fc{
  background-color: #580C1F;
}

.calendarr{
  position: absolute;
  width: 50%;
  margin-top: 1%;
  margin-left: 45%;
  margin-right: 20%;
}

a:not([href]):not([tabindex]){
  color: white;
}

.album{
  margin-top: 4%;
  border: solid 4px black;
}

.fantitle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: black;
  font-size: 5rem;
}



.iconsss{
  position: relative;
  margin-top: -15%;
  width: 20%;
}


.iconsss svg{
  margin-left: 15%;
}


.eventList{
  margin-top: 5%;
}


.column {
  float: right;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.grid{
  position: relative;
  text-align: right;
  width: 50%;
  margin-top: 32%;
  margin-left: 45%;
  padding-bottom: 5%;
}


.events{
  position: absolute;
  border: solid 2px black;
  margin-left: 5%;
  width: 35%;
  height: 59%;
  margin-top: 1%;
}





@media only screen and (max-width: 1299px) and (min-width: 1000px) {
 
  .fantitle{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-top: 2%;
    width: 300px;
    text-align: center;
    color: black;
    font-size: 4rem;
  }

  
.calendarr{
  position: absolute;
  width: 51%;
  margin-top: -10%;
  margin-left: 45%;
  margin-right: 20%;
}

.grid{
  position: relative;
  text-align: right;
  width: 50%;
  margin-top: 25%;
  margin-left: 45%;
}

.iconsss{
  position: relative;
  margin-top: 1%;
  width: 40%;
}

.iconsss svg{
  margin-left: 15%;
}

.events{
  position: absolute;
  border: solid 2px black;
  margin-left: 5%;
  width: 35%;
  height: 60%;
  margin-top: 1%;
}

}

@media only screen and (max-width: 999px) and (min-width: 700px) {
 
 
.fantitle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: black;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 5rem;
}
  .calendarr{
    position: relative;
    width: 90%;
    margin: 0 auto;
  }
  .grid{
    position: relative;
    width: 90%;
    margin: 0 auto;
  }

  .iconsss{
    position: relative;
    margin-top: 5%;
    width: 40%;
    margin: 0 auto;
  }
  
  .iconsss svg{
    margin-left: 15%;
  }

  .events{
    position: relative;
    border: solid 2px black;
    width: 90%;
    height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
}



@media only screen and (max-width: 699px) and (min-width: 300px) {
 
  .fantitle{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-top: 2%;
    width: 300px;
    text-align: center;
    color: black;
    font-size: 3rem;
  }

  .calendarr{
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .grid{
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  .iconsss{
    position: relative;
    margin-top: 5%;
    width: 80%;
    margin: 0 auto;
  }
  
  .iconsss svg{
    margin-left: 15%;
  }

  .events{
    position: relative;
    border: solid 2px black;
    margin-left: 0px;
    width: 100%;
    height: 100%;
    margin-top: 1%;
  }
}


@media only screen and (max-width: 1800px) and (min-width: 1300px) {
  .iconsss{
    position: relative;
    margin-top: -10%;
    width: 20%;
  }
  
  
  .iconsss svg{
    margin-left: 15%;
  }  

}

.map{
  margin-top: 5%;
  margin-left: 1%;
  width: 90vw;
  height: 80vh;
}

.containn{
  height: 100%;
  width: 60%;
  border: solid 2px black;
}


.album1{
  border: solid 2px black;
}


.column1 {
  float: right;
  width: 21%;
  padding: 20px;
}


.photogrid{
  position: absolute;
  top: 34%;
  left: -5%;
}

.row1::after {
  content: "";
  clear: both;
  display: table;
}

.iconS{
  position: relative;
  margin-top: 2%;
  width: 20%;
  margin-left: 80%;
}


.iconS svg{
  margin-left: 15%;
}


.location{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: black;
  font-size: 2rem;
  position: relative;
  display: block;
  width: 20%;
  left: 75%;
  top: -58%;
}

.address{
  position: relative;
  font-weight: bold;
  display: block;
  width: 40%;
  left: 64%;
  top: -48%;
  color: black;
  font-size: large;
}

@media only screen and (max-width: 1199px) and (min-width: 800px) {

  .column1 {
    float: left;
    width: 35%;
    padding: 20px;
  }
  .photogrid{
    position: absolute;
    top: 34%;
    margin-left: 4%;
  }
  .map{
  margin-top: 30%;
  margin-left: 1%;
  width: 90vw;
  height: 60vh;
  }
  .containn{
    height: 100%;
    width: 60%;
    border: solid 2px black;
  }

  .location{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: black;
    font-size: 2rem;
    position: relative;
    display: block;
    width: 50%;
    left: 60%;
    margin-top: -12%;
  }
  
  .address{
    position: relative;
    font-weight: bold;
    display: block;
    width: 40%;
    left: 64%;
    top: -45%;
    color: black;
    font-size: large;
  }

  .iconS{
    position: relative;
    margin-top: 2%;
    width: 30%;
    margin-left: 70%;
  }
  
  
  .iconS svg{
    margin-left: 15%;
  }
  
}


@media only screen and (max-width: 799px) and (min-width: 500px) {

  .column1 {
    float: left;
    width: 45%;
    padding: 20px;
  }
  .photogrid{
    position: absolute;
    top: 34%;
    margin-left: 4%;
  }
  .map{
  margin-top: 40%;
  margin-left: 1%;
  width: 90vw;
  height: 60vh;
  }
  .containn{
    height: 100%;
    width: 60%;
    border: solid 2px black;
  }

  .location{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: black;
    font-size: 2rem;
    position: relative;
    display: block;
    width: 50%;
    left: 60%;
    margin-top: -15%;
  }
  
  .address{
    position: relative;
    font-weight: bold;
    display: block;
    width: 40%;
    left: 64%;
    top: -50%;
    color: black;
    font-size: large;
  }

  .iconS{
    position: relative;
    margin-top: 5%;
    width: 50%;
    margin-left: 1%;
  }
  
  
  .iconS svg{
    margin-left: 15%;
  }
  
}




@media only screen and (max-width: 499px) and (min-width: 300px) {

  .column1 {
    float: left;
    width: 50%;
    padding: 20px;
  }
  .photogrid{
    position: absolute;
    top: 30%;
    margin-left: 4%;
  }
  .map{
  margin-top: 50%;
  margin-left: 1%;
  width: 90vw;
  height: 60vh;
  }
  .containn{
    height: 100%;
    width: 60%;
    border: solid 2px black;
  }

  .location{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: black;
    font-size: 2rem;
    position: relative;
    display: block;
    width: 50%;
    left: 60%;
    margin-top: -45%;
  }
  
  .address{
    position: relative;
    font-weight: bold;
    display: block;
    width: 40%;
    left: 64%;
    top: -60%;
    color: black;
    font-size: large;
  }

  .iconS{
    position: relative;
    margin-top: 5%;
    width: 60%;
    margin-left: 1%;
  }
  
  
  .iconS svg{
    margin-left: 15%;
  }
  
}

.bestSellers{
  margin-left: auto;
  margin-right: auto;
  color: black;
  box-shadow: 0px 10px 50px #C35111;
  text-align: center;
  font-size: x-large;
}

.fancyTitle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-top: 2%;
  text-align: center;
  color: black;
  font-size: 5rem;
}
.cigars{
  font-family: 'Crimson', Arial, Helvetica, sans-serif;
  position: relative;
  width: 100%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  color: black;
  box-shadow: 0px 10px 50px #C35111;  
  text-align: center;
  font-size: x-large;
}


.iconss{
  position: relative;
  margin-top: 2%;
  width: 20%;
  margin-left: 80%;
}


.iconss svg{
  margin-left: 15%;
}


@media only screen and (max-width: 1200px) and (min-width: 1000px) {
  .iconss{
    position: relative;
    margin-top: 3%;
    width: 30%;
    margin-left: 70%;
  }
}


@media only screen and (max-width: 999px) and (min-width: 700px) {
  .iconss{
    position: relative;
    margin-top: 5%;
    width: 40%;
    margin-left: 60%;
  }
}

@media only screen and (max-width: 699px) and (min-width: 300px) {
  .iconss{
    position: relative;
    margin: 0 auto;
    margin-top: 10%;
    padding-left: 10%;
    width: 100%;
    /* margin-left: 10%; */
  }
}


.cigars::after {
  content: "";
  background: url(/static/media/Cigars.ce7abe62.png) no-repeat;
  opacity: 0.5;
  top: 10%;
  left: 20%;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}


.cigars th{
  font-size: 3rem;
  color: orange;
  padding: 30px;
}

  .button2{
    width: 5%;
    display: flex;
    position: absolute;
    left: 92%;
    top: 5%;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #FF9900;
    border-radius: 20px;
  }
  
  .Hidemessage{
    display: none;
  }
  .wrapper0 {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 20%;
    justify-content: center;
    align-items: center;
    background-color: #13241F;
  }
  
  .form-wrapper {
    margin-bottom: 10%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }

  .hide{
      display: none;
  }


  .form-wrapper2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }

  .fancytitle{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: 2%;
    text-align: center;
    color: black;
    font-size: 5rem;
  }

  .caption{
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      font-weight: bold;
      margin-bottom: 2%;
      text-align: center;
      color: black;
  }

  .success{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: darkorange;
    margin-bottom: 10%;
    margin-top: 10%;
    text-align: center;
}


  .caption2{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 2%;
    text-align: center;
    color: black;
}
  

  
  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  
  .label {
    font-size: 0.8em;
    margin-bottom: 0.25em;
    color: #222;
    font-weight: lighter;
  }
  
  input {
    padding: 10px 10px;
    margin-left: auto;
    width: 80%;
    margin-right: auto;
    border-radius: 20px;
    color: black;
    outline: none;
    border: 2px solid #C35111;
    background-color: transparent;
  }
  

  input::-webkit-input-placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }
  

  input:-ms-input-placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }
  

  input::-ms-input-placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }
  

  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }



  @media only screen and (max-width: 699px) and (min-width: 400px) {
  input {
    padding: 10px 10px;
    margin-left: auto;
    width: 100%;
    margin-right: auto;
    border-radius: 20px;
    color: black;
    outline: none;
    border: 2px solid #C35111;
    background-color: transparent;
  }
  .form-wrapper2 {
    width: 50%;
    display: flex;
    padding: 5px 5px;
    flex-direction: column;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }
  .form-wrapper {
    width: 50%;
    display: flex;
    padding: 5px 5px;
    flex-direction: column;
    box-shadow: 0px 10px 50px black;
    margin-left: auto;
    margin-right: auto;
  }
  }

  @media only screen and (max-width: 399px) and (min-width: 300px) {

    .form-wrapper2 {
      width: 100%;
      display: flex;
      padding: 5px 5px;
      flex-direction: column;
      box-shadow: 0px 10px 50px black;
      margin-left: auto;
      margin-right: auto;
    }
    .form-wrapper {
      width: 100%;
      display: flex;
      padding: 5px 5px;
      flex-direction: column;
      box-shadow: 0px 10px 50px black;
      margin-left: auto;
      margin-right: auto;
    }

  }

  

  input.error {
    border: 1px solid red;
    box-shadow: 0px 5px 10px red;
  }
  
  textarea.error {
    border: 1px solid red;
    box-shadow: 0px 5px 10px red;
  }

  .errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
    margin-top: 1%;
    margin-left: 12%;
  }
  
  
  
  .email,
  .name,
  .subject,
  .message,
  .name2,
  .email2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }


  textarea::-webkit-input-placeholder{
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }


  textarea:-ms-input-placeholder{
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }


  textarea::-ms-input-placeholder{
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }


  textarea::placeholder{
    font-size: 1.2em;
    font-weight: lighter;
    color: black;
  }

  .message textarea{
    padding: 10px 10px;
    margin-left: auto;
    width: 80%;
    margin-right: auto;
    border-radius: 20px;
    color: black;
    outline: none;
    border: 2px solid #C35111;
    background-color: transparent;
    height: 200px;
  }
  

  
  .createAccount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .button {
    background-color: grey;
    color: black;
    border: 2px solid #C35111;
    width: 40%;
    margin-top: 1em;
    border-radius: 10px;
    padding: 8px 0px;
    font-size: 1em;
    font-weight: lighter;
    letter-spacing: 1px;
    margin-bottom: 0.25em;
    outline: none;
  }
  
  
  .button:hover {
    color: darkorange;
    background-color: #13241F;
    border: 2px solid #C35111;
    box-shadow: 0px 5px 10px #C35111;
    outline: none;
  }


