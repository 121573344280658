
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.bestSellers{
  margin-left: auto;
  margin-right: auto;
  color: black;
  box-shadow: 0px 10px 50px #C35111;
  text-align: center;
  font-size: x-large;
}

.fancyTitle{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-top: 2%;
  text-align: center;
  color: black;
  font-size: 5rem;
}
.cigars{
  font-family: 'Crimson', Arial, Helvetica, sans-serif;
  position: relative;
  width: 100%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  color: black;
  box-shadow: 0px 10px 50px #C35111;  
  text-align: center;
  font-size: x-large;
}


.iconss{
  position: relative;
  margin-top: 2%;
  width: 20%;
  margin-left: 80%;
}


.iconss svg{
  margin-left: 15%;
}


@media only screen and (max-width: 1200px) and (min-width: 1000px) {
  .iconss{
    position: relative;
    margin-top: 3%;
    width: 30%;
    margin-left: 70%;
  }
}


@media only screen and (max-width: 999px) and (min-width: 700px) {
  .iconss{
    position: relative;
    margin-top: 5%;
    width: 40%;
    margin-left: 60%;
  }
}

@media only screen and (max-width: 699px) and (min-width: 300px) {
  .iconss{
    position: relative;
    margin: 0 auto;
    margin-top: 10%;
    padding-left: 10%;
    width: 100%;
    /* margin-left: 10%; */
  }
}


.cigars::after {
  content: "";
  background: url("../images/Cigars.png") no-repeat;
  opacity: 0.5;
  top: 10%;
  left: 20%;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}


.cigars th{
  font-size: 3rem;
  color: orange;
  padding: 30px;
}