
.map{
  margin-top: 5%;
  margin-left: 1%;
  width: 90vw;
  height: 80vh;
}

.containn{
  height: 100%;
  width: 60%;
  border: solid 2px black;
}


.album1{
  border: solid 2px black;
}


.column1 {
  float: right;
  width: 21%;
  padding: 20px;
}


.photogrid{
  position: absolute;
  top: 34%;
  left: -5%;
}

.row1::after {
  content: "";
  clear: both;
  display: table;
}

.iconS{
  position: relative;
  margin-top: 2%;
  width: 20%;
  margin-left: 80%;
}


.iconS svg{
  margin-left: 15%;
}


.location{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: black;
  font-size: 2rem;
  position: relative;
  display: block;
  width: 20%;
  left: 75%;
  top: -58%;
}

.address{
  position: relative;
  font-weight: bold;
  display: block;
  width: 40%;
  left: 64%;
  top: -48%;
  color: black;
  font-size: large;
}

@media only screen and (max-width: 1199px) and (min-width: 800px) {

  .column1 {
    float: left;
    width: 35%;
    padding: 20px;
  }
  .photogrid{
    position: absolute;
    top: 34%;
    margin-left: 4%;
  }
  .map{
  margin-top: 30%;
  margin-left: 1%;
  width: 90vw;
  height: 60vh;
  }
  .containn{
    height: 100%;
    width: 60%;
    border: solid 2px black;
  }

  .location{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: black;
    font-size: 2rem;
    position: relative;
    display: block;
    width: 50%;
    left: 60%;
    margin-top: -12%;
  }
  
  .address{
    position: relative;
    font-weight: bold;
    display: block;
    width: 40%;
    left: 64%;
    top: -45%;
    color: black;
    font-size: large;
  }

  .iconS{
    position: relative;
    margin-top: 2%;
    width: 30%;
    margin-left: 70%;
  }
  
  
  .iconS svg{
    margin-left: 15%;
  }
  
}


@media only screen and (max-width: 799px) and (min-width: 500px) {

  .column1 {
    float: left;
    width: 45%;
    padding: 20px;
  }
  .photogrid{
    position: absolute;
    top: 34%;
    margin-left: 4%;
  }
  .map{
  margin-top: 40%;
  margin-left: 1%;
  width: 90vw;
  height: 60vh;
  }
  .containn{
    height: 100%;
    width: 60%;
    border: solid 2px black;
  }

  .location{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: black;
    font-size: 2rem;
    position: relative;
    display: block;
    width: 50%;
    left: 60%;
    margin-top: -15%;
  }
  
  .address{
    position: relative;
    font-weight: bold;
    display: block;
    width: 40%;
    left: 64%;
    top: -50%;
    color: black;
    font-size: large;
  }

  .iconS{
    position: relative;
    margin-top: 5%;
    width: 50%;
    margin-left: 1%;
  }
  
  
  .iconS svg{
    margin-left: 15%;
  }
  
}




@media only screen and (max-width: 499px) and (min-width: 300px) {

  .column1 {
    float: left;
    width: 50%;
    padding: 20px;
  }
  .photogrid{
    position: absolute;
    top: 30%;
    margin-left: 4%;
  }
  .map{
  margin-top: 50%;
  margin-left: 1%;
  width: 90vw;
  height: 60vh;
  }
  .containn{
    height: 100%;
    width: 60%;
    border: solid 2px black;
  }

  .location{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-align: center;
    color: black;
    font-size: 2rem;
    position: relative;
    display: block;
    width: 50%;
    left: 60%;
    margin-top: -45%;
  }
  
  .address{
    position: relative;
    font-weight: bold;
    display: block;
    width: 40%;
    left: 64%;
    top: -60%;
    color: black;
    font-size: large;
  }

  .iconS{
    position: relative;
    margin-top: 5%;
    width: 60%;
    margin-left: 1%;
  }
  
  
  .iconS svg{
    margin-left: 15%;
  }
  
}